import { Amplify , Auth} from 'aws-amplify';
import { Button, Heading, withAuthenticator } from "@aws-amplify/ui-react";
import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter} from "react-router-dom";

import '@aws-amplify/ui-react/styles.css';

import './overrideStyles.css';

import {Header} from './auth/Header'
import {Footer} from './auth/Footer'

import Achievements from "./Achievements";
import PeerContributions from "./PeerContributions";
import AchievementsAdmin from "./AchievementsAdmin";
import AdminEditContribution from "./admin/AdminEditContribution"
import ViewProfile from "./ViewProfile";
import EditProfile from "./EditProfile";
import Profiles from "./Profiles";

import ContributionGuide from "./ContributionGuide";
import CriteriaGuide from "./CriteriaGuide";
import ReviewGuide from "./ReviewGuide";

import { API, graphqlOperation } from 'aws-amplify'
import { getAPNAmbassadorProfile } from "./graphql/queries";

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export function App({ signOut, user }) {

  const [userObject, setUserObject] = useState({first_name: '', last_name: ''});


  const doSignOut = ()=>{
    localStorage.removeItem('apn-ambassador-profile');
    signOut();
  }

   useEffect(()  => {
    const checkLoggedIn = async () =>{
      try{
        let user = await Auth.currentAuthenticatedUser();
        console.log(user);

        // this is the cognito user, so now we need to get the database user.

        API.graphql(graphqlOperation(getAPNAmbassadorProfile,{"cognito_user":user.attributes.sub}))
        .then(res => { 
         console.log("Ambassador profile ", res )

        if (res.data.getAPNAmbassadorProfile!==null){
          setUserObject(res.data.getAPNAmbassadorProfile)
          localStorage.setItem('apn-ambassador-profile' , JSON.stringify(res.data.getAPNAmbassadorProfile))
        }else{
          localStorage.removeItem('apn-ambassador-profile')
        }

  
        }).catch(error => {
         console.log(error);
        });

        return user;
      }catch(e){
          console.log("Not loggedin")
         return null;
      }
    }
    checkLoggedIn();
  },[]);


  return (
    <>
    <Heading style={{"height" : '85px'}}>
    <img src="/AWS_AWS_logo_RGB.png" style={{"height" : '85px'}} alt=""/>
    <div style={{'float': 'right', 'font-size' : '16px'}}>
      {userObject.first_name+ " " + userObject.last_name 
      + " "}
    <Button style={{"height" : '50px' , 'padding': '25px', 'margin-top' : '20px', 'margin-right' : '20px'}} size="large" onClick={doSignOut}>Sign out</Button>
    </div>
    </Heading>
   
    <BrowserRouter>
            <Route exact path="/" component={Achievements} />
            <Route exact path="/profiles" component={Profiles} />
            <Route exact path="/contributions" component={Achievements} />
            <Route exact path="/peerContributions" component={PeerContributions} />
            <Route exact path="/achievementsAdmin" component={AchievementsAdmin} />
            <Route exact path="/viewProfile/:id" component={ViewProfile} />
            <Route exact path="/editProfile/:id" component={EditProfile} />
            <Route exact path="/editContribution/:id" component={AdminEditContribution} />
            <Route exact path="/contributionGuide" component={ContributionGuide} />
            <Route exact path="/criteriaGuide" component={CriteriaGuide} />
            <Route exact path="/reviewGuide" component={ReviewGuide} />

    </BrowserRouter>

    </>
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    /*
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },*/
    Footer
  }
});
